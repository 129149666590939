.container {
    opacity: 40%;
}

.imgComerciosDefault {
    width: 24.77px;
    height: 24.79px;
}

.imgAngleBrackets {
    width: 16px;
    height: 16px;
}

.title {
    .MuiTypography-root {
        color: "#5567AE"
    }
}

.icon {
    svg {
        width: 30px !important;
        height: 30px !important;
        filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(7500%) hue-rotate(89deg) brightness(115%) contrast(113%);
    }
}

.green {
    background-color: rgba(107, 177, 83, 0.73);
    justify-content: space-between;

    &:hover {
        background-color: rgba(79, 150, 55, 0.73);
    }
}

.yellow {
    background-color: rgba(175, 155, 88, 0.73);
    justify-content: space-between;

    &:hover {
        background-color: rgba(144, 116, 57, 0.73);
    }
}

.red {
    background-color: rgba(173, 83, 83, 0.73);
    justify-content: space-between;

    &:hover {
        background-color: rgba(136, 39, 39, 0.73);
    }
}

// .check {
//     filter: brightness(0) saturate(100%) invert(62%) sepia(15%) saturate(7322%) hue-rotate(59deg) brightness(99%) contrast(104%);
// }

// .cancel {
//     filter: brightness(0) saturate(100%) invert(34%) sepia(74%) saturate(5795%) hue-rotate(347deg) brightness(98%) contrast(112%);
// }

// .hourglass {
//     filter: brightness(0) saturate(100%) invert(94%) sepia(64%) saturate(3803%) hue-rotate(321deg) brightness(102%) contrast(101%);
// }