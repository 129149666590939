@import "../sassUtils/indexUtils.scss";

.section {
    // height calc minus header height and button div height
    @include sectionTable;
    margin-top: 20px;
}

.noTableSection {
    @include sectionTable;
    margin-top: 4em;
}