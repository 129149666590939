@mixin sectionTable {
    height: calc(90vh - 150px - 64px);
    padding: 0 1.5em;
}

@mixin sectionTableNoPadding {
    height: calc(100vh - 150px - 64px);
}

@mixin defaultBtn {
    background: linear-gradient(90deg, #5669ac 0%, #67c3d0 100%) 0% 0% no-repeat padding-box;
    border-radius: 21em !important;
    color: #ffffff !important;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0;
    margin-left: 1rem;
    opacity: 1;
    padding: 0.4em 1.2em !important;
    text-decoration: none;
    width: 200px;

    &:hover {
        background: linear-gradient(90deg, #67c3d0, 0%, #5669ac 100%) 0% 0% no-repeat padding-box;
    }

    &:disabled {
        background: #a7a7a7;
    }
}