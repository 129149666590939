.root {
    height: 10vh;
    display: flex;
    background-color: #06132f !important;
    box-shadow: none;

    .toolbar {
        height: 100%;
        display: flex !important;
        justify-content: space-between;
        align-items: center !important;

        .menu {
            display: flex;
            align-items: center;
            width: 33%;

            .menuIcon {
                color: #FFF;
            }

            .wordKey {
                color: #FFF;
                font-size: 1.3em !important;
                font-weight: 100 !important;
                margin: 0 0.5em !important;
            }
        }


        .merchantLogo {
            color: #FFFFFF;
            text-align: center;
            max-height: 90px;
        }

        .userData {
            display: flex;
            align-items: center;
            width: 33%;
            justify-content: flex-end;

            p {
                margin: 0;
                margin-right: 16px;
                font-size: 14px;
            }

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid #FFF;
            }
        }
    }

}

.drawer {
    box-shadow: 0px 3px 6px #00000029;

    :global .MuiPaper-root {
        width: 320px;
        background: #06132f 0% 0% no-repeat padding-box;
        border-radius: 0px 25px 25px 0px;
    }

    .drawerHeader {
        background: #FF6D00;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            text-align: center;
            max-height: 90px;
            max-width: 300px;
        }
    }

    .listContainer {
        flex-grow: 1;
        // padding: 0 1em 0 3%;
    }

    .logout {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 24px;
        color: #FFF;
        height: 40px;

        p {
            font-weight: 500;
        }

        img {
            margin-right: 0.6rem;
            width: 1.3rem;
        }
    }

    .drawerFooter {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 56px;
        padding-left: 24px;
        color: #FFF;
        font-weight: 400;

        img {
            width: 54px;
            margin-left: 8px;
        }
    }
}

.drawerHandler {
    height: 100%;
    width: 100vw;
    background-color: transparent;
    position: absolute;
    z-index: 1150;
    right: 0;
    top: 0;
}