@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

* {
    font-family: "Lato", sans-serif;
    box-sizing: border-box !important;
}
a{
    text-decoration: none;
}
/* Estilo para el contenedor de la barra de desplazamiento */
::-webkit-scrollbar {
    width: 5px; /* Ancho de la barra de desplazamiento */
    background-color: #f1f1f1; /* Color de fondo de la barra */
}

/* Estilo para el pulgar (la parte que se mueve) de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del pulgar */
    border-radius: 10px; /* Redondeo del pulgar */
}

/* Estilo para el contenedor del pulgar */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del pulgar al pasar el ratón por encima */
}

/* Estilo para la pista de la barra de desplazamiento */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color de la pista */
    border-radius: 10px; /* Redondeo de la pista */
}

body {
    margin: 0;
    text-decoration: none !important;
}

label {
    font-weight: bold !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: none !important;
}

.MuiDataGrid-row:first-of-type {
    border-top: 1px solid #f2f2f2;
}

.MuiDataGrid-row:nth-of-type(even) {
    background-color: #fafafa !important;
}
.MuiDataGrid-row:nth-of-type(odd) {
    background-color: #fff !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}

.MuiDataGrid-cell {
    outline: none !important;
}
.MuiDataGrid-cell:hover {
    outline: none !important;
}
.MuiDataGrid-row:hover {
    background-color: #f2f2f2 !important;
}
.MuiDataGrid-columnHeader {
    outline: none !important;
}
