.dropZone {
    border: 1.7px dotted #C5C7C9;
    border-radius: 10px;
    height: 120px;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #EEEEEE;
    border-style: solid ;
    margin-top: 20px;
    p {
        margin-top: 20px;
    }
    img{ 
        cursor: pointer;
    }
}

.containerFile { 
    width: 600px;
    height: 60px;
    border: solid 1px #006E54;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    margin-top: 20px;
    h1{
        color: #006E54;
        font-size: 15px;
        font-weight: 600;
    }
    img{ 
        width: 30.47px;
        height: 30.47px;
    }
}
.btnDelete{
    color:#FF0000;
    font-weight: 600;
    cursor: pointer;
}
.containerFileHorizontalModal{
    width: 192%;
    height: 250px;
    gap: 12px;
}
.dropZoneHorizontal {
    border: 1.7px dotted #C5C7C9;
    border-radius: 10px;
    height: 290px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #EEEEEE;
    border-style: solid ;
    margin-top: 20px;
    p {
        margin-top: 20px;
    }
    img{ 
        cursor: pointer;
    }
}
.dropZoneHorizontalModal{
    border: 1.7px dotted #C5C7C9;
    border-radius: 10px;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #EEEEEE;
    border-style: solid ;
    margin-top: 20px;
    p {
        margin-top: 20px;
    }
    img{ 
        cursor: pointer;
    }
}
.containerFileHorizontal { 
    width: 800px !important;
    align-self: flex-end;
    // height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    margin-top: 20px;
    h1{
        color: #006E54;
        font-size: 15px;
        font-weight: 600;
    }
    img{ 
        width: 30.47px;
        height: 30.47px;
    }
}
.containerUploadHorizontal{
    border: solid 1px #006E54;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative !important;
    p{ 
        position: absolute;
        bottom: 0;
    }
}
.btnDelete{
    color:#FF0000;
    font-weight: 600;
    cursor: pointer;
}
